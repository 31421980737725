@use 'sass:math';

/* Body */
    .body{
        background-color: #ffffff;
    }

/* Header */
    .header{
        .navbar{
            .navbar-brand {
                color: #01A5D9;
                font-size: 55px;
            }
            .nav-link {
                font-size: 20px;
            }
        }
        .hero-image{
            background-image: url("/images/header-image.jpg");
                height: 416px;
                background-repeat: no-repeat;
                background-size: cover;
                background-position: center;
        }
    }

/* CTA Boxes */
    .cta-box {
        position: relative;
    
        .small-triangle-holder {
            position: absolute;
            top: 0;
            right: 0;
            width: 30px;
        }
        
        &.whatson {
            h2 {
                color: #FFC425 !important;
            }
            .small-triangle-svg {
                fill: #FFC425 !important;
            }
            a.btn.btn-primary{
                background-color: #FFC425 !important;
                border-color: #FFC425 !important;
            }
        }
        &.hirehall {
            h2 {
                color: #01A5D9 !important;
            }
            .small-triangle-svg {
                fill: #01A5D9 !important;
            }
            a.btn.btn-primary{
                background-color: #01A5D9 !important;
                border-color: #01A5D9 !important;
            }
        }

        &.businesses {
            h2 {
                color: #00B1B0 !important;
            }
            .small-triangle-svg {
                fill: #00B1B0 !important;
            }
            a.btn.btn-primary{
                background-color: #00B1B0 !important;
                border-color: #00B1B0 !important;
            }
        }
    }

/* Hall Charges */
    .row.list-item{
        padding: 25px 0px;
    }

    .booking-clauses{
        -webkit-clip-path: polygon(85% 0, 100% 20%, 100% 100%, 0 100%, 0 0);
        clip-path: polygon(85% 0, 100% 20%, 100% 100%, 0 100%, 0 0);
        background-color: #fec526;
        color: white;
        padding: 20px;
    }

    .highlight{
        font-weight: bold;
        text-decoration: underline;
    }

/* Calendar */
    .fc-daygrid-event-harness{
        .fc-event-past{
            background-color: #e9ecef !important;
        }
        .masonic{
            background-color: #9febf7;
        }
        .yoga{
            background-color: #f5c0ff;
        }
    }