/*!
 * Font Awesome Pro 6.0.0-beta2 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */
@import 'functions';
@import 'variables';

@font-face {
  font-family: 'Font Awesome 6 Pro';
  font-style: normal;
  font-weight: 900;
  font-display: $fa-font-display;
  src: url('#{$fa-font-path}/fa-solid-900.woff2') format('woff2'),
    url('#{$fa-font-path}/fa-solid-900.ttf') format('truetype');
}

.fas,
.fa-solid {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 900;
}
